<template>
  <tr>
    <wi-list-data-table-actions :config="config" :props="props" />
    
    <!-- ID -->
    <td class="cell-id">
      {{ props.item.id }}
    </td>

    <!-- Endereço Principal -->
    <td class="cell-address">
      <div class="address-container">
        <v-icon small class="mr-2">home</v-icon>
        <strong v-if="props.item.address_full">
          {{ props.item.address_full }}
        </strong>
        <v-chip
          v-if="props.item.is_master"
          x-small
          color="primary"
          class="ml-2"
        >
          <v-icon x-small left>star</v-icon>
          Principal
        </v-chip>
      </div>
    </td>

    <!-- Descrição -->
    <td class="cell-description">
      <div class="d-flex align-left">
        <v-icon small class="mr-2">text-box</v-icon>
        <span v-if="props.item.description" class="description-text">
          {{ props.item.description }}
        </span>
        <span v-else class="description-empty">
          Sem descrição
        </span>
      </div>
    </td>

    <!-- Coordenadas -->
    <td class="cell-coordinates">
      <div v-if="props.item.latitude && props.item.longitude" 
           class="coordinates-container">
        <div class="coordinates-text">
          <v-icon small class="mr-1">map-marker</v-icon>
          {{ props.item.latitude }}<br>{{ props.item.longitude }}
        </div>
      </div>
      <div v-else class="coordinates-empty d-flex align-center">
        <v-icon small class="mr-1">map-marker-off</v-icon>
        Sem coordenadas
      </div>
    </td>
  </tr>
</template>
<script>
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'PersonAddresses',
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions
    }
  }
</script>
<style scoped>
  td {
    padding: 8px 12px !important;
    vertical-align: middle !important;
    border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
  }

  th {
    padding: 8px 12px !important;
    font-weight: 500 !important;
  }

  .cell-id,
  .cell-address,
  .coordinates-container {
    /* cursor: pointer; */
  }

  .cell-address {
    min-width: 300px;
  }

  .address-container,
  .coordinates-text {
    display: flex;
    align-items: center;
  }

  .coordinates-empty,
  .description-empty {
    /* color: rgba(0, 0, 0, 0.38); */
    font-style: italic;
    font-size: 0.875rem;
  }

  .description-text,
  .coordinates-text {
    /* color: rgba(0, 0, 0, 0.7); */
    font-size: 0.875rem;
    text-align: left;
  }
</style>